import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStateContext } from "../../contexts/ContextProvider";
import { addLeaveType, getLeaveTypes, editLeaveType, deleteLeaveRecord, addLeaveGroup, getLeaveGroups, editLeaveGroup,
          addLeaveBalance, getLeaveBalances, editEmployee, getAccrualRuns, runAccrualRun, getLeavePlannerData, getLeaveSettingsData } from "../../services/leaveService";
import { getEmployees } from '../../services/employeeService';
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Select from 'react-select';

const LeaveSettingsPage = () => {
  let { employee, leaveTaken, setLeaveTaken, leaveTypes, setLeaveTypes, leaveGroups, setLeaveGroups, leaveBalances, setLeaveBalances, 
        employees, setEmployees, accrualRuns, setAccrualRuns, setIsLoading } = useStateContext(); 

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      //set this from all leave taken if admin?
      const leave_planner_data = await getLeavePlannerData(employee, leaveTaken);
      // const employees = await getEmployees();
      let leave_taken = leave_planner_data.leave_taken;
      const leave_types = leave_planner_data.leave_types;

      console.log(leave_taken);

      if(leave_taken){
        leave_taken = leave_taken.map((item) => ({
          ...item,
          title: leave_types.find(LTY_item => LTY_item.id === item.title)?.name
        }));
      };

      setEmployees(leave_planner_data.employees);
      setAccrualRuns(leave_planner_data.accrual_runs);
      setLeaveTaken(leave_taken);
      setLeaveTypes(leave_types);
      setLeaveGroups(leave_planner_data.leave_groups);
      setLeaveBalances(leave_planner_data.leave_balances);
      setIsLoading(false);
      // setIsInitialLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    if (accrualRuns.length === 0){
      console.log("sfddsfds");
      fetchLeaveValues();
    } else {
      console.log(accrualRuns);
      setIsLoading(false);
      // setIsInitialLoading(false);
    }
  }, []);        

  //LEAVE TYPES
  const leaveTypesColumns = [
    { field: 'id', headerName: 'Id', width: 20 },
    { field: 'name', headerName: 'Leave Type', width: 300, editable: true },
    {
      field: 'accrual_type',
      headerName: 'Accrual Type',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 12 }, (_, index) => index + 1),
      editable: true,
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        // const hour = Math.floor(index / 2) + 8;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      width: 100,
      type: 'singleSelect',
      valueOptions: Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2) + 0;
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hour.toString().padStart(2, '0')}:${minutes}`;
      }),
      editable: true,
    },
    { field: 'accrual_days', headerName: 'Accrual Days', width: 100, editable: true },   
    { field: 'monday', headerName: 'Monday', width: 100, type: 'boolean', editable: true },  
    { field: 'tuesday', headerName: 'Tuesday', width: 100, type: 'boolean', editable: true },  
    { field: 'wednesday', headerName: 'Wednesday', width: 100, type: 'boolean', editable: true },  
    { field: 'thursday', headerName: 'Thursday', width: 100, type: 'boolean', editable: true },  
    { field: 'friday', headerName: 'Friday', width: 100, type: 'boolean', editable: true },  
    { field: 'saturday', headerName: 'Saturday', width: 100, type: 'boolean', editable: true },  
    { field: 'sunday', headerName: 'Sunday', width: 100, type: 'boolean', editable: true } 
  ];

  const addLeaveTypesDict = { name: "", accrual_type: "", accrual_days: "", monday: "", tuesday: "", wednesday: "", thursday: "", friday: "", saturday: "", sunday: "" }

  let leave_type_ids = [];
  leaveTypes.forEach((leave_type) => { leave_type_ids.push(leave_type["id"]); });

  //LEAVE GROUPS
  const leaveGroupsColumns = [{ field: 'id', headerName: 'Id', width: 20 }].concat(
    leave_type_ids.map((leave_type_id) => { return { field: `${leave_type_id}`, headerName: `${leave_type_id}`, width: 100, type: 'boolean', editable: true }; }));

  const addLeaveGroupsDict = { };
  leaveTypes.forEach((leave_type) => { addLeaveGroupsDict[leave_type["id"]] = ""; });

  //LEAVE BALANCES
  const leaveBalancesColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'employee_number', headerName: 'User', width: 100, editable: true },
  ].concat(
    leave_type_ids.map((leave_type_id) => { return { 
      field: `${leave_type_id}`, 
      headerName: `${leave_type_id}`, 
      width: 100, 
      valueGetter: (params) => params.row.balances[`${leave_type_id}`]["available"], 
      editable: true }; }));

  const addLeaveBalancesDict = { "balances": {} };
  leaveTypes.forEach((leave_type) => { addLeaveBalancesDict["balances"][leave_type["id"]] = ""; });

  let leave_group_ids = [];
  leaveGroups.forEach((leave_group) => { leave_group_ids.push(leave_group["id"]); });

  //EMPLOYEES
  const options = [
    { value: 1, label: 'Option 1' },
    { value: 2, label: 'Option 2' },
    { value: 3, label: 'Option 3' },
  ];

  const MultiSelectCell = ({ value, id, field, api }) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
  
    const handleChange = (newOptions) => {
      setSelectedOptions(newOptions);
      api.setRowData(id, { ...api.getRow(id), [field]: newOptions }); // Update data
    };
  
    return (
      <Select
        isMulti
        value={selectedOptions}
        options={options}
        onChange={handleChange}
      />
    );
  };

  const employeesColumns = [
    { field: 'employee_number', headerName: 'empNo', width: 20 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'surname', headerName: 'Surname', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'leave_group_id',
      headerName: 'Leave Group',
      width: 100,
      type: 'singleSelect',
      valueOptions: leave_group_ids,
      editable: true,
    },
    {
      field: 'access_level',
      headerName: 'Access Level',
      width: 100,
      type: 'singleSelect',
      valueOptions: ["1", "2", "3"],
      editable: true,
    },
  ];  

  //ACCRUAL RUNS
  const accrualRunsColumns = [
    // { field: 'id', headerName: 'Id', width: 20 },
    { field: 'month', headerName: 'Month', width: 300, editable: true },
    { field: 'run_number', headerName: 'Run Number', width: 300, editable: true },
    { field: 'total_runs', headerName: 'Total Runs', width: 300, editable: true },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <CollapsableCard 
        cardHeader="LEAVE TYPES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveTypes} 
          setRows={setLeaveTypes} 
          otherColumns={[leaveTypesColumns, addLeaveTypesDict]} 
          backendCRUD = {{
            "C": addLeaveType,
            "R": getLeaveTypes,
            "U": editLeaveType,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE TYPE",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE GROUPS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveGroups} 
          setRows={setLeaveGroups} 
          otherColumns={[leaveGroupsColumns, addLeaveGroupsDict]} 
          backendCRUD = {{
            "C": addLeaveGroup,
            "R": getLeaveGroups,
            "U": editLeaveGroup,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE GROUP",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="LEAVE BALANCES"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={leaveBalances} 
          setRows={setLeaveBalances} 
          otherColumns={[leaveBalancesColumns, addLeaveBalancesDict]} 
          backendCRUD = {{
            "C": addLeaveBalance,
            "R": getLeaveBalances,
            "U": addLeaveBalance,
            "D": deleteLeaveRecord,
            "TYPE": "LEAVE BALANCE",
          }}
          actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="EMPLOYEES"
        expanded={false}
        cardContent={
          <CustomDataGrid
            rows={employees} 
            // rows={employees.map((employee) => ({...employee, id: [employee.employee_number] }))} 
            setRows={setEmployees} 
            otherColumns={[employeesColumns, ""]} 
            backendCRUD = {{
              "C": false,
              "R": getEmployees,
              "U": editEmployee,
              "D": false,
              "TYPE": false,
          }}
            actions={true}
          />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="ACCRUAL RUNS"
        expanded={false}
        cardContent={
          <CustomDataGrid 
          rows={accrualRuns} 
          setRows={setAccrualRuns} 
          otherColumns={[accrualRunsColumns, []]} 
          backendCRUD = {{
            "C": null,
            "R": getAccrualRuns,
            "U": runAccrualRun,
            "D": null,
            "TYPE": "ACCRUAL RUNS",
          }}
          actions={false}
          extraButtons={[
            {icon: <AddIcon />, label: "Run", func:runAccrualRun, func_action: {"action":"run"}},
            {icon: <RemoveIcon />, label: "Undo Last Accrual", func:runAccrualRun, func_action: {"action":"undo"}}
          ]}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

    </Box>
  );  
};

export default LeaveSettingsPage;