import { Box, IconButton, useTheme, Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { useStateContext } from "../../contexts/ContextProvider";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { setToken, setEmployee, 
    // setBeforeLoginMessage, setEmployees, setAllLeaveTaken, setLeaveTaken, setLeaveBalances, setSelectedLeave, 
    // setSelectedEditLeave, setSelectedLeaveType, setLeaveGroups, setLeaveTypes, setScreenSize, setCurrentColor, setCurrentMode, setThemeSettings, 
    // setActiveMenu, setIsClicked, setCurrentEvents, setOpenPopup, setOpenFailedPopup, setOpenCalendarPopup, setOpenDeleteEventPopup, setDuration, 
    // setResultMessage, setRange, 
    setLoggedOut } = useStateContext(); 
  const navigate = useNavigate();

  // const initialState = {chat: false, cart: false, userProfile: false, notification: false,};

  const handleLogout = () => {
    // console.log("Token cleared")
    localStorage.removeItem("token");
    setToken(null);
    setEmployee(null);
    setLoggedOut(true);

    window.location.reload();
    // navigate("/login");



    // setBeforeLoginMessage(null);
    // setEmployees([]);
    // setAllLeaveTaken([]);
    // setLeaveTaken([]);
    // setLeaveBalances([]);
    // setSelectedLeave(null);
    // setSelectedEditLeave(null);
    // setSelectedLeaveType(1);
    // setLeaveGroups(null);
    // setLeaveTypes(null);
    // setScreenSize(undefined);
    // setCurrentColor('#03C9D7');
    // setCurrentMode('Light');
    // setThemeSettings(false);
    // setActiveMenu(true);
    // setIsClicked(initialState);
    // setCurrentEvents([]);
    // setOpenPopup(false);
    // setOpenFailedPopup(false);
    // setOpenCalendarPopup(false);
    // setOpenDeleteEventPopup(false);
    // setDuration(0);
    // setResultMessage("");
    // setRange([{startDate: new Date(2023, 11, 1, 12, 17, 49), endDate: new Date(2023, 11, 1, 12, 17, 49), key: 'selection'}])
  }

  const handleSettingsClick = () => {
    navigate("/settings");
  }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleSettingsClick}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;