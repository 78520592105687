import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'
import { addDays, subDays, differenceInBusinessDays } from 'date-fns'

import { Box, Button, TextField, MenuItem } from "@mui/material";
import { useStateContext } from '../../contexts/ContextProvider';
import Popup from '../Popup';
import { workDuration } from '../../services/leaveService';

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRangeComp = () => {  
  // openCalendarPopup close
  const { openCalendarPopup, setOpenCalendarPopup, range, setRange, setManualDuration } = useStateContext(); 
  // const [openCalendarPopup, setOpenCalendarPopup] = useState(false)
  // console.log("RANGE", range)

  // get the target element to toggle 
  const refOne = useRef(null)

  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpenCalendarPopup(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (ref) => (e) => {
    // console.log(ref.current)
    // console.log(e.target)
    if( ref.current && ! ref.current.contains(e.target) ) {
      setOpenCalendarPopup(false)
    }
  }

  const handleDateChange = (item) => {
    // console.log("DATE CHANGE", item.selection)
    setRange([item.selection])
  }

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside(refOne), true)
  }, [])

  return (
    <div className="calendarWrap">

      {/* <input
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
        readOnly
        className="inputBox"
        onClick={ () => setOpenCalendarPopup(openCalendarPopup => !openCalendarPopup) }
        /> */}

      <TextField
        fullWidth
        variant="filled"
        type="text"
        label="Period"
        // onBlur={handleBlur}
        // onChange={handleChange}
        value={`${format(range[0].startDate, "dd/MM/yyyy")} - ${format(range[0].endDate, "dd/MM/yyyy")}`}
        onClick={ () => setOpenCalendarPopup(openCalendarPopup => !openCalendarPopup) }
        name="period"
        // error={!!touched.reason && !!errors.reason}
        // helperText={touched.reason && errors.reason}
      />

      <div ref={refOne}>
        {openCalendarPopup && 
          // <Popup 
          //   openPopup = {openCalendarPopup}
          //   setOpenPopup = {setOpenCalendarPopup}
          // >
          <Box>
            <DateRange
              onChange={item => handleDateChange(item)}
              // onChange={item => setRange([item.selection])}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="horizontal"
              className="calendarElement"
            />

            {/* <TextField
              select
              fullWidth
              variant="filled"
              label="Start Date From"
              value={differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}
            >
              <MenuItem value={differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}>
                {differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}
              </MenuItem>
            </TextField>
            <TextField
              select
              fullWidth
              variant="filled"
              label="End Date To"
              value={differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}
            >
              <MenuItem value={differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}>
                {differenceInBusinessDays(range[0].endDate, range[0].startDate) + 1}
              </MenuItem>
            </TextField>
            <Button
              variant="contained"
              onClick={ () => {
                setManualDuration(true)
              } }
            >
              Apply Manual Duration
            </Button> */}
          {/* // </Popup> */}
          </Box>
        }
      </div>

    </div>
  )
}

export default DateRangeComp