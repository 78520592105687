import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";
import {
  // Container,
  Typography,
  TextField,
  Button,
  Grid,
} from '@mui/material';

import { useStateContext } from "../../contexts/ContextProvider";
import { forgot_password } from '../../services/employeeService';

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { beforeLoginMessage, setBeforeLoginMessage } = useStateContext(); 

  const handleFormSubmit = async (values) => {
    // event.preventDefault();
    try {
      // let employee = await register(values);
      let result = await forgot_password(values);
      let status = result.status;
      let message = result.data.message;

    // console.log("STATUS", status);
    if (status !== 200) {
        setBeforeLoginMessage(message);
      }
      else {
        setBeforeLoginMessage(message);
        //pop up message at the top for successful registration
        navigate("/login");
        // <Navigate to="/login" replace={true} />
      }
    } catch (error) {
      // console.log("error", error)
    }
  };

  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: '100vh' }}
    >
      {/* {beforeLoginMessage && (
        <Navigate to="/login" replace={true} />
      )} */}
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h4" component="h1" sx={{marginBottom: "20px",}}>
          {beforeLoginMessage && <p>{beforeLoginMessage}</p>}
          Forgot Password
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form 
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: "2px",
              }} 
              onSubmit={handleSubmit}
            >
              <Grid item xs={12} sm={12} md={12} sx={{marginTop: "20px",}}>
                <TextField
                  label="Email"
                  type="email"
                  name = "email"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{marginTop: "10px",}}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} sx={{marginTop: "10px",}}>
                <Typography variant="body2">
                  Click the button above to send a reset link to your email{' '}
                </Typography>
                <Typography variant="body2">
                  <Link to="login">Login</Link>
                </Typography>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // leave_type: yup.string().required("required"),
  // reason: yup.string().required("required"),
  // attatchment: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

const initialValues = {
  name: "",
  email: "",
  password: ""
};

export default ForgotPasswordPage;