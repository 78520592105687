import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
// import LeaveApplicationForm from "./scenes/leave_application_form/leave_application_form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import LoginPage from "./scenes/login_and_registration/login";
import RegistrationPage from "./scenes/login_and_registration/registration";
import BlankPage from "./scenes/login_and_registration/blank";
import { useStateContext } from "./contexts/ContextProvider";
import { verifyTokenAndGetUser } from "./services/employeeService";
import LeaveRequestsPage from "./scenes/settings/leave_requests";
import LeaveSettingsPage from "./scenes/settings/leave_settings";
import ForgotPasswordPage from "./scenes/login_and_registration/forgot_password";
import ResetPasswordPage from "./scenes/login_and_registration/reset_password";

function App() {
  const [theme, colorMode] = useMode();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebar, setIsSidebar] = useState(true);
  const { token, employee, setEmployee, loggedOut, setLoggedOut } = useStateContext(); 

  useEffect(() => {
    handleTokenVerification(token);
  }, []);

  const handleTokenVerification = async (token) => {//can pass a function as variable to set employee if I want to move this method to employeeService.js
    if (token && !employee){
      try {
        let result = await verifyTokenAndGetUser(token);
        let status = result.status;
  
        // console.log("STATUS", status);
        // console.log("MESSAGE", message);
        if (status !== 200) {
          localStorage.removeItem("token");
          window.location.reload();
          setIsLoading(false);
          return false;
        }
        else {
          setLoggedOut(false);
          setEmployee(result.data.employee)
          setIsLoading(false);
          return true;
        }
      } catch (error) {
        console.log("error", error)
      }
    } else {
      setIsLoading(false);
      return false;
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(loggedOut) ? 
          <Routes>
            <Route path="/" element={<BlankPage />} />
            <Route path="*" element={<BlankPage />} />
          </Routes>
          :
          (!employee) ?
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
            : 
            <div className="app">
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  {/* <Route path="/login" element={<LoginPage />} /> */}
                  {/* <Route path="/register" element={<RegistrationPage />} /> */}
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/form" element={<Form />} />
                  {/* <Route path="/lform" element={<LeaveApplicationForm />} /> */}
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/leave-planner" element={<Calendar />} />
                  <Route path="/leave-settings" element={<LeaveSettingsPage />} />
                  {/* admin routes */}
                  {employee.access_level !== 3 && (
                    <>
                      <Route path="/leave-requests" element={<LeaveRequestsPage />} />
                      <Route path="/bar" element={<Bar />} />
                      <Route path="/pie" element={<Pie />} />
                      <Route path="/line" element={<Line />} />
                      <Route path="/geography" element={<Geography />} />
                    </>
                    )}
                  <Route path="*" element={<Dashboard />} />
                </Routes>
              </main>
            </div>
        }
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;