import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStateContext } from "../../contexts/ContextProvider";
import { getApproveeLeaveTaken, actionLeaveTaken } from "../../services/leaveService";
import CustomDataGrid from '../../components/CustomDataGrid';
import CollapsableCard from '../../components/CollapsableCard';
import Popup from "../../components/Popup";
import LeaveApprovalForm from "../leave_application_form/leave_approval_form";
import Select from 'react-select';
import moment from 'moment';

const LeaveRequestsPage = () => {
  let { employee, approveeLeaveTaken, setApproveeLeaveTaken, openPopup, setOpenPopup, openFailedPopup, setOpenFailedPopup, resultMessage,
      selectedLeave, setSelectedLeave, isLoading, setIsLoading } = useStateContext(); 

  const refPopup = useRef(null);
  const refFailedPopup = useRef(null);

  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      //set this from all leave taken if admin?
      const approvee_leave_taken = await getApproveeLeaveTaken(employee);

      setApproveeLeaveTaken(approvee_leave_taken);
      setIsLoading(false);
      // setIsInitialLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    if (approveeLeaveTaken.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      // setIsInitialLoading(false);
    }
  }, []);

  const handleViewClick = (row_id) => {
    setSelectedLeave(approveeLeaveTaken.filter((row) => row.id === row_id));
    setOpenPopup(true);
  }

  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenPopup(true);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);

      window.location.reload();
    }
  };

  //UNACTIONED LEAVE
  const leaveColumns = [
    { field: 'employee_number', headerName: 'Employee', width: 20 },
    { field: 'leave_type_id', headerName: 'Leave Type', width: 150 },
    { field: 'start_date', headerName: 'Start Date', type: 'date', width: 150, valueFormatter: (params) => moment(params.value).format('DD-MM-YYYY')},
    { field: 'end_date', headerName: 'End Date', type: 'date', width: 150, valueFormatter: (params) => moment(params.value).format('DD-MM-YYYY') },    
    { field: 'duration', headerName: 'Duration', width: 150 },
    // { field: 'application_reason', headerName: 'Application Reason', width: 150 },
    // { field: 'attachment', headerName: 'Attachment', width: 150 },
    {
      field: 'status',
      headerName: 'Action',
      width: 100,
      type: 'singleSelect',
      valueOptions: ["pending", "approved", "declined"],
      editable: true,
      // cellClassName: (params) => {
      //   switch (params.value) {
      //     case 'pending':
      //       return 'status-pending';
      //     case 'approved':
      //       return 'status-approved';
      //     case 'declined':
      //       return 'status-declined';
      //     default:
      //       return '';
      //   }
      // },
    },
    { field: 'actioned_by', headerName: 'Actioned by', width: 150 },
    // { field: 'action_reason', headerName: 'Action Reason', width: 150, editable: true },
    { 
      field: 'viewLeaveColumn',
      headerName: 'View Leave', 
      width: 150, 
      renderCell: ({ value, id, api }) => (
        <Button 
          onClick={() => handleViewClick(id)} 
          type="button" 
          color="secondary" 
          variant="contained"
        >
          View
        </Button>
      )
    },
  ];

  //computing in frontend instead of backend
  // const leave_group_ids_included_in = leaveGroups.map((row) => row.leave_approvers.includes(employee.employee_number) ? row.id : null).filter((item) => item !== null);
  // const employees_managed = employees.map((employee) => leave_group_ids_included_in.includes(employee.leave_group_id) ? employee.employee_number : null).filter((item) => item !== null);
  // approveeLeaveTaken = allLeaveTaken.filter((row) => (row.status === "pending" && employees_managed.includes(row.employee_number)))


  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <CollapsableCard 
        cardHeader="UNACTIONED LEAVE"
        expanded={true}
        cardContent={
          <CustomDataGrid 
            //filter by checking if it's not approved or declined or the editor has not already edited
            rows={approveeLeaveTaken.filter((row)=> (row.status === "pending") && (!(JSON.parse(row.actioned_by === null ? "[]" : row.actioned_by)).includes(employee.employee_number)))}
            setRows={setApproveeLeaveTaken} 
            otherColumns={[leaveColumns, false]} 
            backendCRUD = {{
              "C": false,
              "R": getApproveeLeaveTaken,
              "U": actionLeaveTaken,
              "D": false,
              "TYPE": false,
            }}
            actions={false}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      {/* const string = list.join(','); */}
      {/* const newList = string.split(','); */}

      <CollapsableCard 
        cardHeader="ACTIONED LEAVE"
        expanded={false}
        cardContent={
          <CustomDataGrid 
            //filter by checking if it's approved or declined or the editor has already edited
            rows={approveeLeaveTaken.filter((row)=> (JSON.parse(row.actioned_by === null ? "[]" : row.actioned_by)).includes(employee.employee_number))} 
            setRows={setApproveeLeaveTaken}
            otherColumns={[[...leaveColumns.map(col => ({ ...col, editable: ((col.field === 'status' || col.field === 'action_reason') && (employee.employee_number !== 1)) 
                                                                              ? false : col.editable }))], false]} 
            backendCRUD = {{
              "C": false,
              "R": getApproveeLeaveTaken,
              "U": actionLeaveTaken,
              "D": false,
              "TYPE": false,
            }}
            actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="CURRENTLY ON LEAVE"
        expanded={false}
        cardContent={
          <CustomDataGrid 
            //filter by checking if it's approved or declined or the editor has already edited
            rows={approveeLeaveTaken.filter((row)=> (JSON.parse(row.actioned_by === null ? "[]" : row.actioned_by)).includes(employee.employee_number))} 
            setRows={setApproveeLeaveTaken}
            otherColumns={[[...leaveColumns.map(col => ({ ...col, editable: ((col.field === 'status' || col.field === 'action_reason') && (employee.employee_number !== 1)) 
                                                                              ? false : col.editable }))], false]} 
            backendCRUD = {{
              "C": false,
              "R": getApproveeLeaveTaken,
              "U": actionLeaveTaken,
              "D": false,
              "TYPE": false,
            }}
            actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}

      <CollapsableCard 
        cardHeader="GOING ON LEAVE SOON"
        expanded={false}
        cardContent={
          <CustomDataGrid 
            //filter by checking if it's approved or declined or the editor has already edited
            rows={approveeLeaveTaken.filter((row)=> (JSON.parse(row.actioned_by === null ? "[]" : row.actioned_by)).includes(employee.employee_number))} 
            setRows={setApproveeLeaveTaken}
            otherColumns={[[...leaveColumns.map(col => ({ ...col, editable: ((col.field === 'status' || col.field === 'action_reason') && (employee.employee_number !== 1)) 
                                                                              ? false : col.editable }))], false]} 
            backendCRUD = {{
              "C": false,
              "R": getApproveeLeaveTaken,
              "U": actionLeaveTaken,
              "D": false,
              "TYPE": false,
            }}
            actions={true}
        />
        }
      />

      <Box sx={{ mt: 2 }} /> {/* Add spacing between the cards */}



      <div ref={refPopup}>
        {openPopup && 
          <Popup 
            openPopup = {openPopup}
            setOpenPopup = {setOpenPopup}
          >
            <LeaveApprovalForm 
              viewedLeave = {selectedLeave}
            />
          </Popup>
        }
      </div>

      <div ref={refFailedPopup}>
        {openFailedPopup && 
          <Popup 
            openPopup = {openFailedPopup}
            setOpenPopup = {setOpenFailedPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Failed 
              <br />
              {/* {console.log(selectedLeave)} */}
              {/* {selectedLeave.event.title} */}
              <br />
              {resultMessage}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleRetry} type="button" color="secondary" variant="contained">
                    Retry
                </Button>
                <Button onClick={() => handleFailedPopupClick("OK")} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

    </Box>
  );  
};

export default LeaveRequestsPage;